import "./Menu.css";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function alert_T(btn_text) {
    const getAPI = async () => {
        const resp = await fetch(`/api/data/`);
        const resp_text = await resp.text();
        console.log(`${btn_text}를 눌렀구만! ${resp_text}`);
        
    };
    getAPI();
}


function menu_js() {
    const buttons = document.querySelectorAll(".menu__item");
    let activeButton = document.querySelector(".menu__item.active");

    buttons.forEach(item => {
        const text = item.querySelector(".menu__text");
        setLineWidth(text, item);

        window.addEventListener("resize", () => {
            setLineWidth(text, item);
        })

        item.addEventListener("click", function () {
            alert_T(this.querySelector("strong").textContent);
            if (this.classList.contains("active")) return;
            this.classList.add("active");
            if (activeButton) {
                activeButton.classList.remove("active");
                activeButton.querySelector(".menu__text").classList.remove("active");
            }
            handleTransition(this, text);
            activeButton = this;
        });
    });


    function setLineWidth(text, item) {
        const lineWidth = text.offsetWidth + "px";
        item.style.setProperty("--lineWidth", lineWidth);
    }

    function handleTransition(item, text) {
        item.addEventListener("transitionend", (e) => {
            if (e.propertyName !== "flex-grow" ||
                !item.classList.contains("active")) return;
            text.classList.add("active");
        });
    }
}

const Menu = () => {
    const navigate = useNavigate();
    useEffect(() => {
        menu_js();
    });
    return (
        <header>
            <menu className="menu">
                <button onClick={() => {navigate("/");}} className="menu__item active">
                    <div className="menu__icon" >

                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.1 145" >

                            <g id="home-anm">
                                <path strokeLinejoin="round" strokeLinecap="round" d="M70.5,80.1h40.7" />
                                <path d="M35,64v80" />
                                <path d="M145.1,143V63" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M24.9,70l65.7-50.7L156.3,70" />
                            </g>

                            <path strokeLinejoin="round" d="M145.1,117.6v33.1c0,1.5-1.2,2.8-2.8,2.8h-28.4c-1.5,0-2.8-1.2-2.8-2.8V126c0-11.3-9.2-20.5-20.5-20.5l0,0
                      c-11.3,0-20.5,9.2-20.5,20.5v27.5h16H37.8c-1.5,0-2.8-1.2-2.8-2.8v-34.2"/>

                        </svg>

                    </div>
                    <strong className="menu__text active">home</strong>
                </button>

                <button className="menu__item">
                    <div className="menu__icon" >

                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.1 145">

                            <g id="strategy-anm" >
                                <path d="M84.1,50.4L72,64.7c-2,2.4-5.2,3.5-8.3,3l-40.1-6.8c-3.2-0.6-5.8,2.4-4.8,5.5L42,127.9c1.2,3.6,4.6,6.1,8.4,6.1
                          h81.6c3.9,0,7.3-2.6,8.5-6.3l21.5-61.4c0.9-3-1.7-6-4.9-5.4l-38.3,6.7c-3,0.6-6.2-0.5-8.2-2.8L97.4,50.2
                          C93.8,46.3,87.6,46.3,84.1,50.4z"/>
                            </g>
                            <path strokeLinecap="round" d="M38.8,153.5h105.5" />
                            <path strokeLinecap="round" d="M66.8,112.5h49.5" />
                            <path id="strategy-cir1" strokeWidth="0" fill="currentColor" d="M32.4,37.5c0,5.8-4.7,10.5-10.5,10.5s-10.5-4.7-10.5-10.5S16.1,27,21.9,27S32.4,31.7,32.4,37.5z" />
                            <path id="strategy-cir2" strokeWidth="0" fill="currentColor" d="M102.3,23.5c0,5.8-4.7,10.5-10.5,10.5s-10.5-4.7-10.5-10.5S86,13,91.8,13S102.3,17.7,102.3,23.5z" />
                            <path id="strategy-cir3" strokeWidth="0" fill="currentColor" d="M169.6,37.5c0,5.8-4.7,10.5-10.5,10.5s-10.5-4.7-10.5-10.5S153.3,27,159.1,27S169.6,31.7,169.6,37.5z" />

                        </svg>

                    </div>
                    <strong className="menu__text">strategy</strong>
                </button>

                <button className="menu__item">
                    <div className="menu__icon" >

                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.1 145" >

                            <g id="period-cir">
                                <path fill="currentColor" strokeWidth="0" d="M96.5,141.7c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7C93.4,134.6,96.5,137.8,96.5,141.7z" />
                                <path fill="currentColor" strokeWidth="0" d="M78.2,126.7c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-3.9,3.1-7,7-7S78.2,122.8,78.2,126.7z" />
                                <path fill="currentColor" strokeWidth="0" d="M110.6,120.5c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S110.6,116.6,110.6,120.5z" />

                            </g>
                            <g id="period-anm">
                                <path strokeLinecap="round" d="M30.8,24.7h118.3 M117.3,71.2l9.7-6.7c0.7-0.4,1.1-1.1,1.1-1.9V24.7H50v37.8" />
                                <path strokeLinecap="round" d="M149.4,153.3H30.6 M75.5,90.7l-23.1,21.2c-1.6,1.4-2.4,3.4-2.4,5.6v35.9h78.1V113" />
                                <g id="period-line">
                                    <path strokeLinecap="round" d="M50,62.5l40,44.2" />
                                    <path strokeLinecap="round" d="M128.1,111.7L95.2,73.4" />
                                </g>
                            </g>

                        </svg>

                    </div>
                    <strong className="menu__text">period</strong>
                </button>

                <button onClick={() => {navigate("/security");}} className="menu__item">
                    <div className="menu__icon" >

                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.1 145" >

                            <path strokeLinecap="round" d="M94,139c-4.8,1.3-8.8,1.7-11.4,1.8c0,0-18.3,1.1-36.9-11.6c-1.9-1.3-4.7-3.2-7.8-6.2c-1.7-1.6-2.9-2.9-3.4-3.6
                      c0,0-3.6-4.2-6.1-8.6c-4.6-8.4-5.4-18.9-5.5-21l0,0V75.5v-39c0-0.7,0.5-1.3,1.2-1.5l58-14.2c0.2-0.1,0.5-0.1,0.7,0l57.9,14.7
                      c0.7,0.2,1.1,0.8,1.1,1.5v29.7"/>
                            <path id="security-cir" strokeLinecap="round" d="M158.3,120.7c0,18.3-14.8,33.1-33.1,33.1s-33-14.8-33-33.1s14.8-33.1,33.1-33.1S158.3,102.4,158.3,120.7z" />
                            <g id="security-strok">
                                <path strokeLinecap="round" d="M151.1,104.5l-25,25c-1.3,1.3-3.5,1.3-4.9,0l-9.1-9.1" />
                                <path strokeLinecap="round" d="M82.6,43L23.1,62.3" />
                                <path strokeLinecap="round" d="M82.6,68.4L23.1,87.6" />
                            </g>

                        </svg>

                    </div>
                    <strong className="menu__text">security</strong>
                </button>

                <button className="menu__item">
                    <div className="menu__icon" >

                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.1 145" >

                            <circle cx="90.5" cy="90.2" r="19.7" />
                            <g id="settings-anm" >
                                <path strokeLinejoin="round" d="M144.7,73.8l-6.2-1c-0.6-1.5-1.2-3-1.9-4.5l3.6-5.1c3.2-4.4,2.7-10.5-1.2-14.3l-7.4-7.4
                          c-2.1-2.1-4.9-3.3-7.8-3.3c-2.3,0-4.5,0.7-6.4,2.1l-5.1,3.6c-1.6-0.7-3.2-1.4-4.8-2l-1-6.1c-0.9-5.4-5.5-9.3-10.9-9.3H85.1
                          c-5.4,0-10,3.9-10.9,9.2L73.1,42c-1.5,0.6-3,1.2-4.5,1.9l-5-3.6c-1.9-1.4-4.1-2.1-6.5-2.1c-3,0-5.8,1.2-7.8,3.3l-7.4,7.4
                          c-3.8,3.8-4.3,9.8-1.2,14.3l3.7,5.2c-0.7,1.5-1.3,3-1.8,4.5l-6.1,1c-5.4,0.9-9.3,5.5-9.3,10.9v10.5c0,5.4,3.9,10,9.2,10.9l6.3,1.1
                          c0.6,1.5,1.2,3,1.9,4.5l-3.6,5c-3.2,4.4-2.7,10.5,1.2,14.3l7.4,7.4c2.1,2.1,4.9,3.3,7.8,3.3c2.3,0,4.5-0.7,6.4-2.1L69,136
                          c1.4,0.6,2.8,1.2,4.2,1.7l1,6.2c0.9,5.4,5.5,9.3,10.9,9.3h10.5c5.4,0,10-3.9,10.9-9.2l1-6.2c1.5-0.6,3-1.2,4.5-1.9l5.1,3.6
                          c1.9,1.4,4.1,2.1,6.5,2.1c3,0,5.7-1.2,7.8-3.3l7.4-7.4c3.8-3.8,4.3-9.8,1.2-14.3l-3.6-5.1c0.7-1.5,1.3-3,1.9-4.5l6.2-1
                          c5.4-0.9,9.3-5.5,9.3-10.9V84.8C153.9,79.3,150.1,74.7,144.7,73.8z"/>
                            </g>

                        </svg>

                    </div>
                    <strong className="menu__text">settings</strong>
                </button>

            </menu>
        </header>
    )
}

export default Menu;