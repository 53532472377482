import style from "./SecurityTool.module.css"

import React, { useEffect, useState } from 'react';


const SecurityTool = () =>{
    const [ClientInfo, setClientInfo] = useState({});

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch('https://api.secuops.workers.dev/client_info');
        const data = await response.json();
        setClientInfo(data);
      };
      fetchData();
  }, []);

    return(
    <div>
        <label className={`${style.label}`}>
            <input type="text" id="ipaddr" placeholder="&nbsp;"></input>
            <button>OK</button>
        </label>
        <label>
            <h1>ClientInfo</h1>
            <pre>{JSON.stringify(ClientInfo, null, 2)}</pre>
        </label>
    </div>
    )
}
export default SecurityTool;