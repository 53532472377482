//import logo from './logo.svg';
import './App.css';
import Menu from "./components/Menu";

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Post from './components/post';
import SecurityTool from './components/SecurityTools';
import IndexBg from './components/IndexBgs';

/*
export function IndexBg() {
  return(
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/index_bg.js`;
    script.async = true;
    script.type = "module";
    document.body.appendChild(script); 
  })
  );
}
*/

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Menu />
        <Routes>
          <Route path="/" exact element={<IndexBg/>} />
          <Route path="/post/:id" element={<Post />} />
          <Route path={"/security"} element={<SecurityTool />} />
        </Routes>
      </BrowserRouter>


    </div>
  );
  // <Route path="/" exact element={<IndexBg/>} />
  /*
    return (
      <div className="App">
      
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.~
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    );
  */
}

export default App;
